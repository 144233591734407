import React, { useState, useEffect, useContext } from "react";
import db from "../../db";
import globalState from "../../context";
import util from "../../util";
import screenNames from "../../constants/screenNames";
import { Popover, Whisper, Button, ButtonToolbar } from "rsuite";

function CustomTooltip({ message }) {
  return (
    <Whisper
      placement="left"
      followCursor
      visible
      speaker={
        <Popover visible title="Relevant Information">
          {message}
        </Popover>
      }>
      <Button>
        <span className="material-icons">info</span>
      </Button>
    </Whisper>
  );
}

export default CustomTooltip;
