import React, { useContext, useEffect } from "react";
import globalState from "../../context";

const Modal = ({ children, onClose, elClass = "", hasClose = true, hasNavigation = false, onNavigateForward, onNavigateBack }) => {
  const { state, setState } = useContext(globalState);
  const { modalIsOpen } = state;

  return (
    <div
      className={`modal ${elClass}`}
      onScroll={(e) => {
        // console.log("scrolling");
        // e.target.querySelector(".modal-content").style.height = `100vh`;
      }}>
      <div className="modal-content">{children}</div>
      {hasClose === true && (
        <div id="modal-actions">
          {hasNavigation && (
            <span className="material-icons-round back-arrow" onClick={() => onNavigateBack("back")}>
              arrow_back_ios
            </span>
          )}
          <span
            onClick={() => {
              document.querySelector(".page-container").style.top = "70px";
              setState({ ...state, modalIsOpen: false });
              onClose();
            }}
            className="close material-icons-round">
            cancel
          </span>
          {hasNavigation && (
            <span className="material-icons-round forward-arrow" onClick={() => onNavigateForward("forward")}>
              arrow_forward_ios
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default Modal;
