import { getDatabase, ref, set, get, child, onValue, push, remove } from "firebase/database";
import { getStorage, ref as storageRef, deleteObject } from "firebase/storage";
import util from "./util";
import FirebaseStorage from "./firebaseStorage";

const db = {
  tables: {
    expenseTracker: "expenseTracker",
    swapRequests: "swapRequests",
    users: "users",
    calendarEvents: "calendarEvents",
    transferChange: "transferChange",
    inbox: "inbox",
  },
  convertKeyObjectToArray: (keyObject) => {
    return Object.entries(keyObject).map((x) => x[1]);
  },
  getFilteredRecords: async (records, currentUser) => {
    let returnRecords = [];
    if (records && records !== undefined && records.length > 0) {
      const coparentPhones = currentUser.coparents.map((x) => x.phone);
      records.forEach((record, index) => {
        // console.log(record);
        // Filter by phone
        const mergedPhoneArrays = util.getUniqueArray(coparentPhones.concat(record.shareWith));
        // console.log(mergedPhoneArrays.includes(currentUser.phone));
        if (mergedPhoneArrays.includes(currentUser.phone) || record.phone === currentUser.phone) {
          returnRecords.push(record);
        }
      });
    }

    // console.log(returnRecords);
    return returnRecords;
  },
  getAllUserRecords: (tableName, currentUser, objectName) => {
    return new Promise((resolve, reject) => {
      db.getRecordsByUser(tableName, currentUser, objectName)
        .then((currentUserRecord) => {
          if (!Array.isArray(currentUserRecord)) {
            currentUserRecord = db.convertKeyObjectToArray(currentUserRecord);
            currentUserRecord = currentUserRecord.map((x) => {
              x.canDelete = true;
              return x;
            });
          }
          if (currentUserRecord !== undefined) {
            resolve(currentUserRecord);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          //
        });
    });
  },
  getCoparentRecords: (tableName, currentUser, objectName) => {
    return new Promise(async (resolve, reject) => {
      const coparentPhones = currentUser.coparents.map((x) => x.phone);
      coparentPhones.forEach(async (coparentPhone) => {
        db.getCoparent(coparentPhone).then(async (coparentObj) => {
          db.getRecordsByUser(tableName, coparentObj, objectName)
            .then((coparentRecord) => {
              if (!Array.isArray(coparentRecord)) {
                coparentRecord = db.convertKeyObjectToArray(coparentRecord).filter((x) => x.shareWith.includes(currentUser.phone));
              }
              if (coparentRecord !== undefined) {
                resolve(coparentRecord);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              //
            });
        });
      });
    });
  },
  getCurrentUserRecords: (tableName, currentUser, objectName) => {
    return new Promise((resolve, reject) => {
      db.getRecordsByUser(tableName, currentUser, objectName)
        .then((currentUserRecord) => {
          if (!Array.isArray(currentUserRecord)) {
            currentUserRecord = db.convertKeyObjectToArray(currentUserRecord);
            currentUserRecord = currentUserRecord.map((x) => {
              x.canDelete = true;
              return x;
            });
          }
          if (currentUserRecord !== undefined) {
            resolve(currentUserRecord);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          //
        });
    });
  },
  getAllFilteredRecords: async (tableName, currentUser, objectName) => {
    let currentUserRecords = undefined;
    let coparentRecords = undefined;
    const getCoparentRecords = new Promise(async (resolve, reject) => {
      const coparentPhones = currentUser.coparents.map((x) => x.phone);
      coparentPhones.forEach(async (coparentPhone) => {
        db.getCoparent(coparentPhone).then(async (coparentObj) => {
          db.getRecordsByUser(tableName, coparentObj, objectName)
            .then((coparentRecord) => {
              if (!Array.isArray(coparentRecord)) {
                coparentRecord = db.convertKeyObjectToArray(coparentRecord).filter((x) => x.shareWith.includes(currentUser.phone));
              }
              if (coparentRecord !== undefined) {
                // console.log(coparentRecord);
                resolve(coparentRecord);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              //
            });
        });
      });
    });
    const getUserRecords = new Promise((resolve, reject) => {
      db.getRecordsByUser(tableName, currentUser, objectName)
        .then((currentUserRecord) => {
          if (!Array.isArray(currentUserRecord)) {
            currentUserRecord = db.convertKeyObjectToArray(currentUserRecord);
            currentUserRecord = currentUserRecord.map((x) => {
              x.canDelete = true;
              return x;
            });
          }
          if (currentUserRecord !== undefined) {
            resolve(currentUserRecord);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          //
        });
    });

    await getUserRecords
      .then((data) => {
        currentUserRecords = data;
      })
      .then(async () => {
        getCoparentRecords.then((coparentData) => {
          coparentRecords = coparentData;
        });
      });
    const mergedRecords = [...(coparentRecords ?? []), ...(currentUserRecords ?? [])];
    return mergedRecords;
  },
  getUserIndex: async (user) => {
    const dbRef = ref(getDatabase());
    let userIndex;
    await get(child(dbRef, `users`)).then((snapshot) => {
      if (snapshot.exists()) {
        userIndex = snapshot.val().findIndex((x) => x && x.id === user.id);
      }
    });
    return userIndex;
  },
  getUserIndexByPhone: async (phone) => {
    const dbRef = ref(getDatabase());
    let userIndex;
    await get(child(dbRef, `users`)).then((snapshot) => {
      if (snapshot.exists()) {
        userIndex = snapshot.val().findIndex((x) => x && x.phone === phone);
      }
    });
    return userIndex;
  },
  getRecordsByUser: async (tableName, currentUser, objectName) => {
    return new Promise(async (resolve, reject) => {
      const dbRef = ref(getDatabase());
      const userIndex = await db.getUserIndex(currentUser);
      await get(child(dbRef, `${tableName}/${userIndex}/${objectName}`))
        .then((snapshot) => {
          if (snapshot.exists() && userIndex > -1) {
            // console.log("if");
            resolve(snapshot.val());
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getCoparent: async (coparentPhone, currentUser, getBioCoparent) => {
    return new Promise(async (resolve, reject) => {
      const dbRef = ref(getDatabase());
      await get(child(dbRef, `users`)).then((snapshot) => {
        if (snapshot.exists()) {
          const tableData = snapshot.val();
          if (snapshot.exists() && tableData.length > 0) {
            tableData.forEach((firebaseUser) => {
              if (firebaseUser.phone === coparentPhone) {
                resolve(firebaseUser);
              }
            });
          }
        }
      });
    });
  },
  add: async (tableName, data) => {
    const dbRef = ref(getDatabase());
    let tableData = [];
    tableData = await db.getTable(tableName);
    if (tableData === null) {
      tableData = [data];
    } else {
      if (Array.isArray(tableData)) {
        if (tableData.length > 0) {
          tableData = [...tableData, data].filter((item) => item);
        } else {
          tableData = [data];
        }
      } else {
        tableData = Object.entries(tableData);
        if (tableData === null || tableData.length === 0) {
          tableData = [data];
        } else {
          tableData = [...tableData, data];
        }
      }
    }
    set(child(dbRef, tableName), tableData);
  },
  addOrUpdateToObject: async (tableName, currentUser, objectName, value, id) => {
    const dbRef = ref(getDatabase());
    const tableRecords = await db.getTable(tableName);
    let toUpdate = tableRecords.filter((x) => x.id === currentUser.id)[0];
    let userIndex = await db.getUserIndex(currentUser);
    if (toUpdate[objectName] !== undefined && toUpdate[objectName].length > 0) {
      toUpdate[objectName] = [...toUpdate[objectName], value];
    } else {
      toUpdate[objectName] = [value];
    }
    if (id) {
      await push(child(dbRef, `${tableName}/${userIndex}/${objectName}/${id}/`), value);
      return toUpdate[objectName];
    } else {
      await push(child(dbRef, `${tableName}/${userIndex}/${objectName}`), value);
      return toUpdate[objectName];
    }
  },
  delete: async (tableName, prop, id) => {
    const dbRef = ref(getDatabase());
    let idToDelete;
    const tableRecords = await db.getTable(tableName);
    tableRecords.forEach((record, index) => {
      if (record.id === id) {
        idToDelete = index;
      }
    });
    remove(child(dbRef, `${tableName}/${idToDelete}/`));
  },
  deleteImage: async (tableName, currentUser, id, prop) => {
    const dbRef = ref(getDatabase());
    const userIndex = await db.getUserIndex(currentUser);
    let toDeleteId;
    if (prop) {
      const tableRecords = await db.getTable(tableName);
      let userToUpdate = tableRecords.filter((x) => x.id === currentUser.id)[0];
      if (userToUpdate === undefined || userToUpdate[prop] === undefined || userToUpdate[prop].length === 0) {
        userToUpdate[prop] = [];
      } else {
        const asArray = Object.entries(userToUpdate[prop]);
        asArray.forEach((img, index) => {
          if (img[1].id === id) {
            toDeleteId = img[0];
          }
        });
      }
    } else {
      await db.getTable(tableName).then(async (data) => {
        let tableData = [];
        if (data && data.length > 0) {
          tableData = data.filter((x) => {
            return x.id !== id;
          });
        }
        await set(child(dbRef, tableName), tableData);
      });
    }
    remove(child(dbRef, `${tableName}/${userIndex}/${prop}/${toDeleteId}/`));
  },
  getUserImages: async (tableName, currentUser, objectName) => {
    const dbRef = ref(getDatabase());
    let tableData = [];
    let userIndex;
    await get(child(dbRef, tableName)).then((snapshot) => {
      if (snapshot.exists()) {
        userIndex = snapshot.val().findIndex((x) => x.id === currentUser.id);
      }
    });
    await get(child(dbRef, `${tableName}/${userIndex}/${objectName}`)).then((snapshot) => {
      tableData = snapshot.val();
    });
    return tableData;
  },
  getTable: async (tableName) => {
    const dbRef = ref(getDatabase());
    let tableData = [];
    await get(child(dbRef, tableName)).then((snapshot) => {
      tableData = snapshot.val();
    });
    return tableData;
  },
  // getCoparentRecords: async (tableName, currentUser) => {
  //   await db.getTable(db.tables[tableName]).then((data) => {
  //     return data;
  //   });
  // },
  updateRecord: async (tableName, recordToUpdate, prop, value, identifier) => {
    const dbRef = ref(getDatabase());
    const tableRecords = await db.getTable(tableName);
    let toUpdate;
    if (identifier && identifier !== undefined) {
      toUpdate = tableRecords.filter((x) => x[identifier] === recordToUpdate[identifier])[0];
    } else {
      toUpdate = tableRecords.filter((x) => x.id === recordToUpdate.id)[0];
      // console.log("to update: ", toUpdate);
    }
    toUpdate[prop] = value;
    set(child(dbRef, tableName), tableRecords);
  },
  addToRecord: async (path, newValue) => {
    const dbRef = ref(getDatabase());
    push(child(dbRef, path), newValue);
  },
  updateCoparent: async (currentUser, coparent, prop, value) => {
    const dbRef = ref(getDatabase());
    const tableRecords = await db.getTable(db.tables.users);
    let toUpdate;
    currentUser.coparents.forEach((cop) => {
      const userIndex = tableRecords.findIndex((x) => x.name === currentUser.name);
      toUpdate = tableRecords[userIndex].coparents.filter((x) => x.name === coparent.name)[0];
    });
    toUpdate[prop] = value;
    set(child(dbRef, db.tables.users), tableRecords);
  },
  updatePhoneOrEmail: async (currentUser, prop, value) => {
    const dbRef = ref(getDatabase());

    // Update cal table
    await db.getTable(db.tables.swapRequests).then((data) => {
      if (data && data.length > 0) {
        data.forEach((request, index) => {
          // Update user request
          if (request.shareWith.includes(currentUser.name)) {
            // console.log(request);
            const numberIndex = request.shareWith.indexOf(currentUser.phone);
            request.shareWith[numberIndex] = value;
          }
          if (request[prop] === currentUser.phone) {
            request[prop] = value;
          }
        });
        // console.log(data);
        set(child(dbRef, tableName), data);
      }
    });

    // Update expenseTracker table
    await db.getTable(db.tables.expenseTracker).then((data) => {
      if (data && data.length > 0) {
        data.forEach((request) => {
          // Update user expenseTracker
          if (request[prop] === currentUser.phone) {
            request[prop] = value;
          }
          if (request.shareWith.includes(currentUser.phone)) {
            const numberIndex = request.shareWith.indexOf(currentUser.phone);
            request.shareWith[numberIndex] = value;
          }
        });
        // set(child(dbRef, tableName), data);
      }
    });
    // Update cal table
    await db.getTable(db.tables.calendarEvents).then((data) => {
      if (data && data.length > 0) {
        data.forEach((event) => {
          // Update user cal
          if (event[prop] === currentUser.phone) {
            event[prop] = value;
          }

          if (event.shareWith.includes(currentUser.phone)) {
            const numberIndex = event.shareWith.indexOf(currentUser.phone);
            event.shareWith[numberIndex] = value;
          }
        });
        // console.log(data);
        // set(child(dbRef, tableName), data);
      }
    });

    // Update users table
    await db.getTable(db.tables.users).then((data) => {
      if (data && data.length > 0) {
        data.forEach((record) => {
          // Update user record
          if (record[prop] === currentUser.phone) {
            record[prop] = value;
          }

          // Update prop for coparents
          record.coparents.forEach((coparent) => {
            if (coparent[prop] === currentUser.phone) {
              coparent[prop] = value;
            }
          });
        });
        // set(child(dbRef, tableName), data);
      }
    });
  },
  onTableChange: (tableName, callback) => {
    const dbRef = ref(getDatabase());

    onValue(child(dbRef, tableName), (snapshot) => {
      const tableData = snapshot.val();
      if (callback) callback(tableData);
    });
  },
  updateAllUsers: async (prop, value) => {
    const tableName = db.tables.users;
    const dbRef = ref(getDatabase());
    const tableRecords = await db.getTable(tableName).then((users) => {
      // console.log(users);
      users = users.map((x) => x.length > 0 && x !== undefined);
      users.map((x) => {
        if (x[prop] !== undefined) {
          // console.log(x);
          x[prop] = value;
        }
      });
      // user[prop] = value;
      return users;
    });
    // console.log(tableRecords);
    set(child(dbRef, tableName), tableRecords);
  },
};

export default db;
