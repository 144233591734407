import React, { useState, useEffect, useContext } from "react";
import db from "../../../db";
import globalState from "../../../context";
import Alert from "../../shared/alert";
import util from "../../../util";
import screenNames from "../../../constants/screenNames";
import ThemeToggle from "./themeToggle";
import CheckboxGroup from "../../shared/checkboxGroup";

export default function Settings() {
  const { state, setState } = useContext(globalState);
  const { currentUser, currentScreenTitle, theme, setTheme } = state;

  const toggleNotifications = async (e) => {
    util.handleCheckboxSelection(
      e,
      ".box",
      (e) => {},
      async (e) => {
        await db.updateRecord(db.tables.users, currentUser, "allowNotifications", e === "Yes" ? true : false);
      },
      false
    );
  };

  useEffect(() => {
    const checkboxContainer = document.getElementById("checkbox-container");
    checkboxContainer.querySelector(".box").classList.add("active");
    util.scrollToTopOfPage();
    setState({ ...state, currentScreenTitle: `Settings` });
    setTimeout(() => {
      document.querySelector(".page-container").classList.add("active");
    }, 100);
  }, []);

  return (
    <div id="settings-container" className="page-container">
      <div className="sections">
        <p className="section" onClick={() => setState({ ...state, currentScreen: screenNames.myProfile, currentScreenTitle: "My Profile" })}>
          <span className="material-icons-round accent">manage_accounts</span>My Profile <span className="material-icons-round go-arrow">edit</span>
        </p>
        <div className="section theme">
          <span className="material-icons-round accent">brightness_medium</span>
          <span>Color Theme</span>
          <ThemeToggle />
        </div>
        <div className="section notifications">
          <span className="material-icons-round accent">notifications</span>
          <span>Allow Notifications</span>
          <CheckboxGroup labels={["Yes", "No"]} onCheck={(e) => toggleNotifications(e)} />
        </div>
      </div>
    </div>
  );
}
