import React, { useState, useEffect, useContext } from "react";
import globalState from "../../context";

export default function Alert({ className = "" }) {
  const { state, setState } = useContext(globalState);
  const { showAlert, alertMessage, alertType } = state;

  const hide = () => setState({ ...state, showAlert: false, alertMessage: "" });

  useEffect(() => {
    if (alertMessage.length > 0) {
      setTimeout(() => {
        hide();
        setState({ ...state, alertType: "error", showAlert: false, alertMessage: "" });
      }, 2000);
    }
  }, [alertMessage]);

  return (
    <div id="alert-container" className={`${className} ${alertType === "error" ? "error" : "success"} ${showAlert === true ? "show" : ""}`}>
      <div className="text">
        <p>{alertMessage}</p>
      </div>
    </div>
  );
}
