import React, { useEffect, useState, useContext, useRef } from "react";
import moment from "moment";
import util from "../../util";

export default function CheckboxGroup({ labels, onCheck, elClass }) {
  return (
    <div id="checkbox-group" className={elClass ? elClass : ""}>
      {labels &&
        labels.length > 0 &&
        labels.map((label, index) => {
          // label = util.uppercaseFirstLetterOfAllWords(util.formatName(label, true)).replace("true", "");
          return (
            <div id="checkbox-container" data-label={label} className="flex" key={index} onClick={(e) => onCheck(e)}>
              <div className={"box"}>
                <span className="checkmark-icon material-icons-round">check</span>
              </div>
              <span>{label}</span>
            </div>
          );
        })}
    </div>
  );
}
