const ThemeUtil = {
  colors: {
    bg: " #0085d1",
    text: "white",
    greenText: "#25a18e",
    blackText: "rgba(0, 0, 0, 0.637)",
    accent: "#00bedf",
    lightAccent: "#9fffcb",
    yellowLight: "#FFEA00",
    yellowDark: "#fff3cd",
    menuBgDark: "#131517",
    menuBgLight: "whitesmoke",
    modalBgDark: "rgba(0, 0, 0, 0.7)",
    modalBgLight: "rgba(0, 0, 0, 0.2)",
    eventDetailsLight: "rgba(255, 255, 255, 0.9)",
    eventDetailsDark: "rgba(255, 255, 255, 0.05)",
    translucentDark: "rgba(255, 255, 255, 0.2)",
    translucentLight: "rgba(0, 0, 0, 0.1)",
    translucent: "rgba(0, 0, 0, 0.2)",
    purpleLight: "#9d98fc",
    purpleDark: "#9d98fc",
    messageToBgDark: "#5e56f7",
    messageToBgLight: "#5e56f7",
  },
  changeVariable: (variable, color) => {
    document.body.style.setProperty(variable, color);
  },
  changeTheme: (themeColor) => {
    if (themeColor === "dark") {
      ThemeUtil.changeVariable("--bg", ThemeUtil.colors.bg);
      ThemeUtil.changeVariable("--text", ThemeUtil.colors.text);
      ThemeUtil.changeVariable("--accent", ThemeUtil.colors.accent);
      ThemeUtil.changeVariable("--lightAccent", ThemeUtil.colors.lightAccent);
      ThemeUtil.changeVariable("--greenText", ThemeUtil.colors.greenText);
      ThemeUtil.changeVariable("--translucent", ThemeUtil.colors.translucent);
      ThemeUtil.changeVariable("--blackText", ThemeUtil.colors.blackText);
      ThemeUtil.changeVariable("--warning", ThemeUtil.colors.yellowLight);
    } else {
      ThemeUtil.changeVariable("--bg", ThemeUtil.colors.bg);
      ThemeUtil.changeVariable("--text", ThemeUtil.colors.text);
      ThemeUtil.changeVariable("--accent", ThemeUtil.colors.accent);
      ThemeUtil.changeVariable("--lightAccent", ThemeUtil.colors.lightAccent);
    }
  },
};

export default ThemeUtil;
