import React, { useState, useEffect, useContext, useRef } from "react";
import Modal from "./shared/modal";
import globalState from "../context";

export default function InstallAppModal() {
  const { state, setState } = useContext(globalState);
  const { showPwaSteps } = state;
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    setShouldShow(showPwaSteps);
  }, [showPwaSteps]);
  return (
    <Modal elClass={`pwa-modal ${shouldShow ? "active" : ""}`} onClose={() => setState({ ...state, showPwaSteps: false })}>
      <div className="os-container apple">
        <h3>
          <u>Less</u> steps than installing via App Store or Google Play! <br />
          <span className="material-icons-round">auto_awesome</span>
        </h3>
        <h1>
          iOS <ion-icon className="apple" name="logo-apple"></ion-icon>
        </h1>
        <p>While viewing the website...</p>
        <ul>
          <li>
            Tap the Share button <span className="material-icons round">ios_share</span> in the menu bar
          </li>
          <li>
            Scroll down the list of options, then tap <i>Add to Home Screen</i>
          </li>
          <li>If you don’t see Add to Home Screen, you can add it. Scroll down to the bottom of the list, tap Edit Actions, then tap Add to Home Screen.</li>
          <li>Click on the newly added icon on your Home Screen</li>
        </ul>
      </div>
      <div className="os-container android">
        <h1>
          Android <span className="material-icons-round android">android</span>
        </h1>
        <p>While viewing the website...</p>
        <ul>
          <li>
            Tap the menu icon (<ion-icon name="ellipsis-vertical"></ion-icon>)
          </li>
          <li>Tap Add to home screen</li>
          <li>Choose a name for the website shortcut</li>
          <li>Click on the newly added icon on your Home Screen</li>
        </ul>
      </div>
    </Modal>
  );
}
