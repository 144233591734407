const firebaseConfig = {
  apiKey: "AIzaSyAT4fLC_vJB94B-fbDY_r2PBcuJQPDe32E",
  authDomain: "nursesheet.firebaseapp.com",
  projectId: "nursesheet",
  storageBucket: "nursesheet.appspot.com",
  appId: "1:680756008114:web:8b55a86b7e045e0ccc65ab",
};

// Initialize Firebase
export default firebaseConfig;
