import React, { useState, useEffect, useContext } from "react";

export default function Loading({ isLoading }) {
  useEffect(() => {
    const screenTitle = document.querySelector(".screen-title");
    const loadingContainer = document.getElementById("loading-gif-container");
    if (screenTitle) {
      if (isLoading === false) {
        loadingContainer.classList.remove("active");
        screenTitle.style.display = "flex";
      } else {
        loadingContainer.classList.add("active");
        screenTitle.style.display = "none";
      }
    }
  }, [isLoading]);

  return (
    <div id="loading-gif-container">
      <img src={require("../../img/loading.gif")} id="loading-gif" />
    </div>
  );
}
