import React, { useEffect, useState, useCallback } from "react";
import { initializeApp } from "firebase/app";
import firebaseConfig from "./firebaseConfig.js";
import globalState from "./context.js";
import db from "./db.js";
import screenNames from "./constants/screenNames.js";
import Menu from "./components/menu.jsx";
import util from "./util.js";
import { getDatabase, ref, set, get, child, onValue, push, remove } from "firebase/database";

// Screens
import ForgotPassword from "./components/screens/forgotPassword.jsx";
import Registration from "./components/screens/registration.jsx";
import InstallAppModal from "./components/installAppModal.jsx";
import Alert from "./components/shared/alert.jsx";
import ThemeUtil from "./components/utils/themeUtil.js";
import Settings from "./components/screens/settings/settings.jsx";
import Loading from "./components/shared/loading.jsx";
import Login from "./components/screens/login.jsx";
import Report from "./components/screens/report/report.jsx";
import LabValues from "./components/screens/labValues.jsx";

const stateObj = {
  currentScreen: screenNames.login,
  setCurrentScreen: (screen) => {},
  menuIsOpen: false,
  setMenuIsOpen: (isOpen) => {},
  userIsLoggedIn: false,
  setUserIsLoggedIn: (isLoggedIn) => {},
  alertMessage: "",
  setAlertMessage: (alertMessage) => {},
  showAlert: false,
  setShowAlert: (bool) => {},
  alertType: "error",
  setAlertType: (type) => {},
  currentUser: {},
  setCurrentUser: (user) => {},
  showOverlay: false,
  setShowOverlay: (bool) => {},
  modalIsOpen: false,
  setModalIsOpen: (bool) => {},
  theme: "dark",
  setTheme: (theme) => {},
};

export default function App() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const [state, setState] = useState(stateObj);
  const [isLoading, setIsLoading] = useState(false);
  const stateToUpdate = { state, setState };
  const { modalIsOpen, userIsLoggedIn, showOverlay, currentScreen, menuIsOpen, currentScreenTitle, currentUser, unreadMessages, theme, unreadMessagesCountSet } = state;

  const hideMenu = async () => {
    document.querySelector(".overlay").classList.remove("active");
    document.querySelector("#menu").classList.remove("open");
    setState({ ...state, menuIsOpen: false });
    showScreenTitle();
  };

  const showScreenTitle = () => {
    const screenTitle = document.querySelector(".screen-title");
    if (screenTitle) {
      screenTitle.style.display = "flex";
    }
  };

  const changeCurrentScreen = async (screen) => {
    showScreenTitle();
    setState({
      ...state,
      currentScreen: screen,
      menuIsOpen: false,
      setAlertMessage: "",
      setShowAlert: false,
      viewExpenseForm: false,
      currentScreenTitle: util.formatScreenTitle(screen),
    });
  };

  const setCurrentUser = async () =>
    new Promise(async (resolve, reject) => {
      const rememberKey = localStorage.getItem("rememberKey");
      await db
        .getTable(db.tables.users)
        .then(async (people) => {
          if (people && people.length > 0) {
            const user = people.filter((x) => x.id === rememberKey)[0];
            if (user) {
              ThemeUtil.changeTheme(user.theme);
              let count = 0;
              setState({
                ...state,
                theme: user.theme,
                unreadMessages: count,
                currentScreen: screenNames.calendar,
                currentUser: user,
                userIsLoggedIn: true,
                currentScreenTitle: "Shared Calendar",
              });
            } else {
              setIsLoading(false);
              resolve("");
              ThemeUtil.changeTheme("dark");
            }
          } else {
            resolve("");
            ThemeUtil.changeTheme("dark");
          }
        })
        .finally(async () => {
          resolve("");
          setIsLoading(false);
        });
    });

  const onUsersTableChange = async () => {
    if (currentUser) {
      const dbRef = getDatabase();
      const inboxRef = ref(dbRef, `${db.tables.users}`);
      onValue(inboxRef, async (snapshot) => {});
    }
  };

  useEffect(() => {
    // onUsersTableChange();
    setIsLoading(true);
    const menuContainer = document.getElementById("menu-container");

    if (menuContainer) {
      menuContainer.style.display = "block";
    }

    setState({ ...state, currentScreen: screenNames.report });

    // setCurrentUser();

    ThemeUtil.changeTheme("dark");
    setIsLoading(false);

    util.applyVersionNumberToUrl();
  }, []);

  return (
    <div className="App" id="app-container">
      <div onClick={hideMenu} className={showOverlay || (menuIsOpen && userIsLoggedIn) ? "active overlay" : "overlay"}></div>
      <globalState.Provider theme="dark" value={stateToUpdate}>
        <Alert />
        <Loading isLoading={isLoading} />

        <InstallAppModal />
        {currentScreen === screenNames.login && <Login />}
        {currentScreen === screenNames.registration && <Registration />}
        {currentScreen === screenNames.forgotPassword && <ForgotPassword />}
        {currentScreen === screenNames.settings && <Settings />}
        {currentScreen === screenNames.report && <Report />}
        {currentScreen === screenNames.labValues && <LabValues />}
        <div id="menu-container">
          <Menu />
          <div className={`menu-icon-container ${menuIsOpen ? "" : "open"}`}>
            {/* <div
              onClick={(e) => {
                // document.getElementById("unread-count").classList.remove("active");
                setState({ ...state, menuIsOpen: !menuIsOpen });
                document.querySelector(".screen-title").style.display = "none";
              }}
              className={`icon-and-text-container menu `}>
              <span className={`material-icons-outlined menu-icon`}>menu</span>
              <p className="text">Menu</p>
            </div> */}
            <div onClick={(e) => changeCurrentScreen(screenNames.labValues)} className={`icon-and-text-container menu `}>
              <span className={`material-icons-outlined menu-icon`}>science</span>
              <p className="text">Lab Values</p>
            </div>
          </div>
        </div>
      </globalState.Provider>
    </div>
  );
}
