const screenNames = {
  login: "login",
  registration: "registration",
  forgotPassword: "forgotPassword",
  settings: "settings",
  report: "report",
  labValues: "labValues",
};

export default screenNames;
