import React, { useState, useEffect, useContext } from "react";
import db from "../../../db";
import globalState from "../../../context";
import util from "../../../util";
import screenNames from "../../../constants/screenNames";
import CheckboxGroup from "../../../components/shared/checkboxGroup";
import { DatePicker } from "rsuite";
import { Dropdown, Accordion } from "rsuite";
import { FaAngleDoubleDown, FaArrowAltCircleDown, FaArrowDown } from "react-icons/fa";

function PatientInfo() {
  const { state, setState } = useContext(globalState);
  const { currentUser, screenTitle } = state;

  const handleIsoStatus = (e) => {
    util.handleCheckboxSelection(
      e,
      () => {},
      (e) => {
        const isoStatusParent = document.querySelector(".isolation-status");
        const input = isoStatusParent.querySelector(".status-input");
        if (e.toLowerCase() === "yes") {
          input.classList.add("active");
        } else {
          input.classList.remove("active");
        }
      }
    );
  };

  const handleAmbulationStatus = (e) => {
    util.handleCheckboxSelection(
      e,
      () => {},
      (e) => {
        console.log(e);
      }
    );
  };

  const toggleActivePill = (e) => {
    const pill = e.target;
    if (pill.classList.contains("active")) {
      pill.classList.remove("active");
    } else {
      pill.classList.add("active");
    }
  };

  return (
    <div className="section">
      <Accordion>
        <Accordion.Panel header="Patient Info" defaultExpanded caretAs={FaArrowDown}>
          <div className="form">
            <div className="flex double mb-15">
              <div className="input-label-group">
                <label>Patient Name</label>
                <input type="text" />
              </div>
              <div className="input-label-group">
                <label>Date of Birth</label>
                <DatePicker key={util.getUid()} format="MM/dd/yyyy" cleanable={true} className="" onChange={(e) => update("general", "dateOfBirth", moment(e).format("MM/DD/YYYY"))} />
              </div>
            </div>
            <div className="input-label-group mb-15">
              <label>Admitting Physician</label>
              <input type="text" />
            </div>
            <div className="  isolation-status">
              <div className="flex double">
                <div className="input-label-group">
                  <label>Isolation Status</label>
                  <CheckboxGroup labels={["Yes", "No"]} onCheck={(e) => handleIsoStatus(e)} />
                </div>
                <div className="input-label-group mb-15">
                  <label>Room #</label>
                  <input type="number" />
                </div>
              </div>
              <input type="text" placeholder="Status information..." className="mb-15 full-width status-input" />
            </div>
            <div className="input-label-group ambulation-status mb-15">
              <label>Ambulation Status</label>
              <CheckboxGroup elClass={"mb-5"} labels={["1 assist", "2 assist", "Independent", "Non-ambulatory"]} onCheck={(e) => handleAmbulationStatus(e)} />
              <input type="text" placeholder="Ambulation information..." />
            </div>
            <div className="input-label-group">
              <label>Allergies</label>
              <div className="pills">
                <span onClick={(e) => toggleActivePill(e)}>peanuts</span>
                <span onClick={(e) => toggleActivePill(e)}>milk</span>
                <span onClick={(e) => toggleActivePill(e)}>dairy</span>
                <span onClick={(e) => toggleActivePill(e)}>wheat</span>
                <span onClick={(e) => toggleActivePill(e)}>soy</span>
                <span onClick={(e) => toggleActivePill(e)}>fish</span>
                <span onClick={(e) => toggleActivePill(e)}>shellfish</span>
                <span onClick={(e) => toggleActivePill(e)}>tree nuts</span>
                <span onClick={(e) => toggleActivePill(e)}>sesame</span>
              </div>
            </div>
          </div>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}

export default PatientInfo;
