import React, { useState, useEffect, useContext } from "react";
import globalState from "../context";
import screenNames from "../constants/screenNames";
import util from "../util";
import Alert from "./shared/alert";

export default function Menu() {
  const { state, setState } = useContext(globalState);
  const { currentScreen, menuIsOpen, currentUser } = state;

  const changeCurrentScreen = (screen) => {
    console.log(screen);
    setState({
      ...state,
      currentScreen: screen,
      menuIsOpen: false,
      viewExpenseForm: false,
      currentScreenTitle: util.formatScreenTitle(screen),
    });
    document.querySelector(".screen-title").style.display = "flex";
  };

  const logout = () => {
    localStorage.removeItem("rememberKey");
    setState({
      ...state,
      currentScreen: screenNames.login,
      currentUser: null,
      userIsLoggedIn: false,
    });
  };

  useEffect(() => {
    if (menuIsOpen) {
      setState({ ...state, menuIsOpen: true });
    } else {
      setState({ ...state, menuIsOpen: false });
    }
  }, [menuIsOpen]);

  return (
    <div id="menu" className={menuIsOpen ? "open" : ""}>
      <div className="content">
        <div id="menu-title">
          <div className="contents">
            <span className="title-icon material-icons-round">family_restroom</span>
            <p> Peaceful </p>
            <p>
              <span>co</span>Parenting
            </p>
          </div>
        </div>
        <div id="menu-items">
          <div className={`menu-item ${currentScreen === screenNames.calendar ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.calendar)}>
            <span className="material-icons-round">calendar_month</span>
            <p>Shared Calendar</p>
          </div>
          <div className={`menu-item ${currentScreen === screenNames.inbox ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.inbox)}>
            <span className="material-icons-round">question_answer</span>
            <p className="text">Inbox</p>
          </div>
          <div className={`menu-item ${currentScreen === screenNames.expenseTracker ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.expenseTracker)}>
            <span className="material-icons-round">paid</span>
            <p>Expense Tracker</p>
          </div>
          <div className={`menu-item ${currentScreen === screenNames.swapRequests ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.swapRequests)}>
            <span className="material-icons-round">swap_horizontal_circle</span>
            <p>Swap Requests</p>
          </div>
          <div className={`menu-item ${currentScreen === screenNames.transferRequests ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.transferRequests)}>
            <span className="material-icons-round">update</span>
            <p>Transfer Change</p>
          </div>
          <div className={`menu-item ${currentScreen === screenNames.legalDocs ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.legalDocs)}>
            <span className="material-icons-round">description</span>
            <p>Legal Documents</p>
          </div>
          <div className={`menu-item ${currentScreen === screenNames.memories ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.memories)}>
            <span className="material-icons-round">collections</span>
            <p>Memories</p>
          </div>
          <div className={`menu-item ${currentScreen === screenNames.childInfo ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.childInfo)}>
            <span className="material-icons-round">info</span>
            <p>Child Info</p>
          </div>
          <div className={`menu-item ${currentScreen === screenNames.settings ? "active" : ""}`} onClick={() => changeCurrentScreen(screenNames.settings)}>
            <span className="material-icons-round">manage_accounts</span>
            <p>Settings</p>
          </div>
          <div className={`menu-item`} onClick={logout}>
            <span className="material-icons-round">logout</span> <p>Logout</p>
          </div>

          <p id="pwa-steps" onClick={() => setState({ ...state, showPwaSteps: true, menuIsOpen: false })}>
            Install App <span className="material-icons">install_mobile</span>
          </p>

          <p id="menu-user-name">Hello {util.formatName(currentUser.name, true, true)}!</p>
          <span
            onClick={async (e) => {
              document.querySelector(".screen-title").style.display = "flex";
              let messageCount = 0;
              await MessagingUtil.getUnreadMessageCount(currentUser, (count) => {
                messageCount = count;
              }).finally(() => {
                setState({ ...state, unreadMessages: messageCount, menuIsOpen: false });
              });
            }}
            className={`material-icons-round back`}>
            arrow_back_ios
          </span>
        </div>
      </div>
    </div>
  );
}
