import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/bundle.scss";
import App from "./App";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/serviceWorker.js`)
      .then((registration) => {
        var subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array("BHo09uHaF3IyLuRp65H7krhC8SCpsS1mqFsl-vx6GJkrdItWlL5e_qy8XloZml0LBisMLR_9afk5tSwRmYRDYaE"),
        };
        // return registration.pushManager.getSubscription().then(() => {});
        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then((registration) => {
        console.log("[SW] service Worker is registered", registration.scope);
      })
      .catch((err) => {
        console.error("[SW] service Worker registration failed:", err);
      });
  });

  const sendNotification = () => {
    const notifBody = `Created by Me`;
    // const notifImg = `data/img/${games[randomItem].slug}.jpg`;
    const options = {
      body: notifBody,
      // icon: notifImg,
    };
    new Notification("Title", options);
  };

  // sendNotification();
  // Function to convert Base64URL to Uint8Array
  const urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
  };

  // getSubscription();

  // Add a 'push' event listener to the service worker.
  self.addEventListener("push", function (event) {
    // Extract data from the push event
    const data = event.data.json();

    // Options for the notification
    const options = {
      // The message text in the notification
      body: data.message,
      // The icon displayed in the notification
      // icon: "icons/icon-72x72.png",
    };

    // Use waitUntil to keep the service worker active
    // until the notification is displayed
    event.waitUntil(
      self.registration.showNotification("ServiceWorker Cookbook", {
        body: payload,
      })
    );
  });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
