import React, { useState, useEffect, useContext } from "react";
import ToggleButton from "react-toggle-button";
import ThemeUtil from "../../utils/themeUtil";
import globalState from "../../../context";
import db from "../../../db";

export default function ThemeToggle() {
  const { state, setState } = useContext(globalState);
  const { currentUser, currentScreenTitle, theme, setTheme } = state;

  useEffect(() => {
    console.log(theme);
  }, []);

  return (
    <ToggleButton
      inactiveLabel={<span className="material-icons-round">dark_mode</span>}
      activeLabel={<span className="material-icons-round">light_mode</span>}
      value={theme === "dark" ? true : false}
      onToggle={(value) => {
        db.updateRecord(db.tables.users, currentUser, "theme", theme === "dark" ? "light" : "dark");
        ThemeUtil.changeTheme(theme === "dark" ? "light" : "dark");
        setState({ ...state, theme: theme === "dark" ? "light" : "dark" });
      }}
    />
  );
}
