import React, { useState, useEffect, useContext } from "react";
import db from "../../db";
import globalState from "../../context";
import util from "../../util";
import screenNames from "../../constants/screenNames";
import CustomTooltip from "../shared/customTooltip";

const LabValues = () => {
  const { state, setState } = useContext(globalState);
  const { currentUser, screenTitle } = state;

  useEffect(() => {
    util.scrollToTopOfPage();
    setTimeout(() => {
      document.querySelector(".page-container").classList.add("active");
    }, 100);
  }, []);

  return (
    <div id="lab-values-container" className="page-container">
      <p className="screen-title">Lab Values</p>
      <table cellSpacing="0" cellPadding="0" border={0}>
        <thead>
          <th>LABS</th>
          <th>NORMAL RANGE</th>
          <th>INFO</th>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Na+</b>
            </td>
            <td>135 - 145</td>
            <td>
              <CustomTooltip message={"Sodium swells the body"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>K+</b>
            </td>
            <td>3.5 - 5.0</td>
            <td>
              <CustomTooltip message={"Potassium pumps heart"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>Cl-</b>
            </td>
            <td>97 - 107</td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>Ca</b>
            </td>
            <td>9.0 - 10.5</td>
            <td>
              <CustomTooltip message={"Calcium contracts muscles"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>Mg+</b>
            </td>
            <td>1.3 - 2.1</td>
            <td>
              <CustomTooltip message={"Magnesium mellows muscles"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>Albumin</b>
            </td>
            <td>3.5 - 5.0</td>
            <td>
              <CustomTooltip message={"Liver"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>Creatinine</b>
            </td>
            <td>Over 1.3</td>
            <td>
              <CustomTooltip message={"Bad kidney"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>BUN</b>
            </td>
            <td>10 - 20</td>
            <td>
              <CustomTooltip message={"Kidney"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>Glucose</b>
            </td>
            <td>70 - 110</td>
            <td>
              <CustomTooltip message={"Hypogly = brain die"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>WBC</b>
            </td>
            <td>5,000 - 10,000</td>
            <td>
              <CustomTooltip message={"High = infection"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>RBC</b>
            </td>
            <td>M: 4.7 - 6.1 &nbsp;F: 4.2 - 5.4</td>
            <td>
              <CustomTooltip message={"Low = anemia"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>Hgb</b>
            </td>
            <td>M: 14 - 18 &nbsp;F: 12 - 16</td>
            <td>
              <CustomTooltip message={"Below 7 = blood transfusion"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>Hct</b>
            </td>
            <td>M: 42 - 52 &nbsp;F: 37 - 47</td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>PLTS</b>
            </td>
            <td>150k - 400k</td>
            <td>
              <CustomTooltip message={"AsaParin, CloPidogrel"} />
            </td>
          </tr>
          <tr>
            <td>
              <b>PT</b>
            </td>
            <td>11 - 12.5</td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>aPTT</b>
            </td>
            <td>30 -40</td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>INR</b>
            </td>
            <td>0.9 - 1.2</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LabValues;
