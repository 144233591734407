import React, { useState, useEffect, useContext } from "react";
import db from "../../../db";
import globalState from "../../../context";
import util from "../../../util";
import screenNames from "../../../constants/screenNames";
import { DatePicker } from "rsuite";
import { Dropdown, Accordion } from "rsuite";
import PatientInfo from "./patientInfo";
import Modal from "../../shared/modal";
import { FaAngleDoubleDown, FaStar } from "react-icons/fa";
import { BsFileEarmarkMedical } from "react-icons/bs";

const Report = () => {
  const { state, setState } = useContext(globalState);
  const { currentUser, screenTitle } = state;
  const [showModal, setShowModal] = useState(false);
  const [selectedDept, setSelectedDept] = useState("Emergency");
  const [expandDeptAccordion, setExpandDeptAccordion] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState("Patient");
  const [expandPatientAccordion, setExpandPatientAccordion] = useState(false);
  const [selectedCodeStatus, setSelectedCodeStatus] = useState("Code Status");
  const [expandCodeStatusAccordion, setExpandCodeStatusAccordion] = useState(false);
  const [favoriteDept, setFavoriteDept] = useState("");

  const setDepartment = (el) => {
    setSelectedDept(el.target.textContent);
    setExpandDeptAccordion(false);
  };

  const setPatient = (el) => {
    setSelectedPatient(el.target.textContent);
    setExpandPatientAccordion(false);
  };

  const setCodeStatus = (el) => {
    setSelectedCodeStatus(el.target.textContent);
    setExpandCodeStatusAccordion(false);
  };

  const setFavDept = () => {
    if (favoriteDept.length > 0) {
      setFavoriteDept("");
    } else {
      setFavoriteDept(selectedDept);
    }
  };

  useEffect(() => {
    util.scrollToTopOfPage();
    setState({ ...state, currentScreenTitle: "Report" });
    setTimeout(() => {
      document.querySelector(".page-container").classList.add("active");
    }, 100);
  }, []);

  return (
    <>
      <span className="screen-title">
        REPORT <BsFileEarmarkMedical />
      </span>
      <Modal onClose={() => setShowModal(false)} elClass={`${showModal === true ? "code-status-modal active" : "code-status-modal"}`} hasNavigation={false}>
        <div className="content">
          <h4>Code Status Legend</h4>
          <div className="flex">
            <div className="left red">Code Red</div>
            <div className="right">Fire</div>
          </div>
          <div className="flex">
            <div className="left adam">Code Adam</div>
            <div className="right">Infant/Child Abduction</div>
          </div>
          <div className="flex">
            <div className="left black">Code Black</div>
            <div className="right">Bomb/Bomb Threat</div>
          </div>
          <div className="flex">
            <div className="left gray">Code Gray</div>
            <div className="right">Severe Weather</div>
          </div>
          <div className="flex">
            <div className="left orange">Code Orange</div>
            <div className="right">Hazardous Material Spill/Release</div>
          </div>
          <div className="flex">
            <div className="left blue">Code Blue</div>
            <div className="right">Medical Emergency - Adult</div>
          </div>
          <div className="flex">
            <div className="left pink">Code Pink</div>
            <div className="right">Medical Emergency - Pediatric</div>
          </div>
          <div className="flex">
            <div className="left yellow">Code Yellow</div>
            <div className="right">Disaster</div>
          </div>
          <div className="flex">
            <div className="left violet">Code Violet</div>
            <div className="right">Violent/Combative Patient</div>
          </div>
          <div className="flex">
            <div className="left silver">Code Silver</div>
            <div className="right">Person with Weapon/ Hostage Situation</div>
          </div>
          <div className="flex">
            <div className="left brown">Code Brown</div>
            <div className="right">Missing Adult Patient</div>
          </div>
        </div>
      </Modal>
      <div id="report-container" className="page-container">
        <div className="flex code-status">
          <span className="material-icons-outlined code-status-info-icon" onClick={() => setShowModal(true)}>
            info
          </span>
          <Accordion className="code-status dropdown mb-20" onClick={(e) => setExpandCodeStatusAccordion(!expandCodeStatusAccordion)}>
            <Accordion.Panel expanded={expandCodeStatusAccordion === true ? true : false} header={selectedCodeStatus}>
              <p onClick={(e) => setCodeStatus(e)} className="red">
                Code Red
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="adam">
                Code Adam
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="black">
                Code Black
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="gray">
                Code Gray
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="orange">
                Code Orange
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="blue">
                Code Blue
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="pink">
                Code Pink
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="yellow">
                Code Yellow
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="violet">
                Code Violet
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="silver">
                Code Silver
              </p>
              <p onClick={(e) => setCodeStatus(e)} className="brown">
                Code Brown
              </p>
            </Accordion.Panel>
          </Accordion>
        </div>
        <div className="flex primary-dropdowns double mb-20">
          <Accordion className="dropdown" onClick={(e) => setExpandPatientAccordion(!expandPatientAccordion)}>
            <Accordion.Panel expanded={expandPatientAccordion === true ? true : false} header={selectedPatient}>
              <p onClick={(e) => setPatient(e)}>Jack Smith - Room #5</p>
              <p onClick={(e) => setPatient(e)}>Kevin Marmet - Room #2</p>
            </Accordion.Panel>
          </Accordion>
          <div className="flex dept-column dropdown">
            <Accordion onClick={(e) => setExpandDeptAccordion(!expandDeptAccordion)}>
              <Accordion.Panel expanded={expandDeptAccordion === true ? true : false} header={selectedDept}>
                <p onClick={(e) => setDepartment(e)}>Emergency</p>
                <p onClick={(e) => setDepartment(e)}>Intensive Care Unit (ICU)</p>
                <p onClick={(e) => setDepartment(e)}>Med/Surg</p>
                <p onClick={(e) => setDepartment(e)}>Operating Room (OR)</p>
                <p onClick={(e) => setDepartment(e)}>Obstetrics and Gynecology (OB/GYN)</p>
                <p onClick={(e) => setDepartment(e)}>Pediatrics</p>
              </Accordion.Panel>
            </Accordion>
            <FaStar className={favoriteDept === selectedDept ? "active" : ""} onClick={setFavDept} />
          </div>
        </div>
        {/* PATIENT INFO*/}
        <PatientInfo />
      </div>
    </>
  );
};

export default Report;
