import React, { useState, useEffect, useContext, Fragment } from "react";
import db from "../../db.js";
import util from "../../util.js";
import globalState from "../../context.js";
import Alert from "../shared/alert.jsx";
import screenNames from "../../constants/screenNames.js";
import CheckboxGroup from "../shared/checkboxGroup.jsx";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, browserSessionPersistence, setPersistence } from "firebase/auth";

export default function Login() {
  const { state, setState } = useContext(globalState);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  const login = async () => {
    if (util.validation([phone, password]) > 0) {
      setState({ ...state, showAlert: true, alertMessage: "Please fill out all fields" });
      // return false;
    }

    await db.getTable(db.tables.users).then((data) => {
      data = Object.entries(data);
      let foundUser = null;

      data.filter((x) => {
        x.forEach(async (user) => {
          if (user && user.phone && user.password) {
            if (user.phone === phone && user.password === password) {
              foundUser = user;
              if (rememberMe) {
                localStorage.setItem("rememberKey", user.id);
                await db.updateRecord(db.tables.users, user, "rememberMe", true);
              }
            }
          }
        });
      })[0];

      if (foundUser) {
        setState({
          ...state,
          userIsLoggedIn: true,
          currentScreen: screenNames.calendar,
          currentUser: foundUser,
        });
      } else {
        setState({ ...state, showAlert: true, alertMessage: "Incorrect phone and/or password" });
      }
    });
  };

  const toggleRememberMe = (e) => {
    const clickedEl = e.currentTarget;
    const checkbox = clickedEl.querySelector(".box");
    if (checkbox.classList.contains("active")) {
      setRememberMe(false);
      checkbox.classList.remove("active");
    } else {
      checkbox.classList.add("active");
      setRememberMe(true);
    }
  };

  useEffect(() => {
    util.scrollToTopOfPage();
    setState({ ...state, currentScreen: "login", currentScreenTitle: "Login" });
    setTimeout(() => {
      if (document.querySelector(".page-container")) {
        document.querySelector(".page-container").classList.add("active");
      }
    }, 100);
  }, []);

  return (
    <div id="login-container" className="page-container">
      <div className="flex form-container">
        <div className="form">
          <input className="mb-15" type="number" pattern="[0-9]*" inputMode="numeric" placeholder="Phone number" onChange={(e) => setPhone(e.target.value)} />
          <div className="flex inputs">
            <input type={viewPassword ? "text" : "password"} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            {!viewPassword && (
              <span className="material-icons-round" onClick={() => setViewPassword(true)}>
                visibility
              </span>
            )}
            {viewPassword && (
              <span className="material-icons-round" onClick={() => setViewPassword(false)}>
                visibility_off
              </span>
            )}
          </div>
          <div className="flex button-group">
            <button className="button green" onClick={login}>
              Login <span className="material-icons-round">lock_open</span>
            </button>
            <button className="button register" onClick={() => setState({ ...state, currentScreen: screenNames.registration })}>
              Register <span className="material-icons-round">person_add</span>
            </button>
          </div>
          <CheckboxGroup onCheck={toggleRememberMe} labels={["Remember Me"]} />
        </div>

        <p id="forgot-password-link" onClick={() => setState({ ...state, currentScreen: screenNames.forgotPassword })}>
          Forgot Password
        </p>
      </div>
    </div>
  );
}
