import React, { useState, useEffect, useContext, Fragment } from "react";
import db from "../../db.js";
import util from "../../util.js";
import globalState from "../../context.js";
import User from "../../models/user.js";
import screenNames from "../../constants/screenNames.js";
import CheckboxGroup from "../shared/checkboxGroup.jsx";
import PasswordChecklist from "react-password-checklist";

export default function Registration() {
  const { state, setState } = useContext(globalState);

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [children, setChildren] = useState([]);
  const [parentType, setParentType] = useState("");
  const [childrenInputs, setChildrenInputs] = useState([<ChildrenInput childrenCount={1} onChange={(e) => setChildren([...children, e.target.value])} />]);

  const submit = async () => {
    let coparentsArr = [];
    let coparentNamesNl = document.querySelectorAll(".coparent-name");
    let coparentPhonesNl = document.querySelectorAll(".coparent-phone");
    let checkboxesNl = document.querySelectorAll(".coparent-input-container .box.active");
    let checkboxValues = Array.from(checkboxesNl).map((x) => x.parentNode.dataset["label"]);
    const coparentNames = Array.from(coparentNamesNl).map((x) => x.value);
    const coparentPhones = Array.from(coparentPhonesNl).map((x) => x.value);

    coparentNames.forEach((name, nameIndex) => {
      coparentsArr.push({ name: name });
    });
    coparentPhones.forEach((phone, phoneIndex) => {
      coparentsArr[phoneIndex]["phone"] = phone;
    });
    checkboxValues.forEach((checkbox, checkboxIndex) => {
      coparentsArr[checkboxIndex]["parentType"] = checkbox;
    });

    const emptyCoparentProps = coparentsArr.filter((x) => x.name === "" || x.phone === "" || x.parentType === "");

    if (emptyCoparentProps.length > 0) {
      setState({ ...state, showAlert: true, alertMessage: "Empty co-parent fields are not allowed" });
      return false;
    }

    if (checkboxValues.length === 0) {
      setState({ ...state, showAlert: true, alertMessage: "Empty co-parent fields/checkboxes are not allowed" });
      return false;
    }

    if (parentType.length === 0) {
      setState({ ...state, alertMessage: "Select your parent type", showAlert: true });
      return false;
    }

    if (util.validation([userName, email, phone, parentType]) > 0) {
      setState({ ...state, alertMessage: "Please fill out all fields", showAlert: true });
      return false;
    }

    if (!util.formatPhoneNumber(phone)) {
      setState({ ...state, alertMessage: "Enter a valid phone number", showAlert: true });
    }

    if (children.length === 0) {
      setState({ ...state, alertMessage: "Please enter at least 1 child", showAlert: true });
      return false;
    }

    if (coparentsArr.length === 0) {
      setState({ ...state, alertMessage: "Please enter at least 1 coparent", showAlert: true });
      return false;
    }

    // Check for existing account
    // await db.getTable(db.tables.users).then((users) => {
    //   if (users.findIndex((x) => x.email === newUser.email || x.phone === newUser.phone) > -1) {
    //     setAlert("Account already exists, please login");
    //     setState({ ...state, showAlert: true });
    //     return false;
    //   }
    // });

    let newUser = new User();
    newUser.email = email;
    newUser.password = password;
    newUser.name = userName;
    newUser.children = children;
    newUser.phone = phone.replaceAll("-", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "");
    newUser.coparents = coparentsArr;
    newUser.parentType = parentType;

    SmsUtil.send("3307494534", `New User Registration: ${userName}`);
    db.add(db.tables.users, newUser).finally(() => {
      setState({ ...state, currentScreen: screenNames.login });
    });
  };

  const cancel = () => setState({ ...state, currentScreen: screenNames.login });
  const addChild = () => setChildrenInputs([...childrenInputs, <ChildrenInput childrenCount={childrenInputs.length + 1} onChange={(e) => setChildren([...children, e.target.value])} />]);
  const handleParentType = (e) => util.handleCheckboxSelection(e, ".box", setParentType(null), setParentType(e.currentTarget.dataset.label));
  const handleCoparentType = (e) => util.handleCheckboxSelection(e, ".box");
  const addCoparent = () => setCoparentInputs([...coparentInputs, <CoparentInputs coparentsLength={coparentInputs.length + 1} handleCoparentType={handleCoparentType} />]);
  const [coparentInputs, setCoparentInputs] = useState([<CoparentInputs handleCoparentType={handleCoparentType} />]);

  useEffect(() => {
    util.scrollToTopOfPage();
    setState({ ...state, currentScreen: "registration", currentScreenTitle: "Sign Up" });
    setTimeout(() => {
      document.querySelector(".page-container").classList.add("active");
    }, 100);
  }, []);

  return (
    <div id="registration-container" className="page-container">
      <div className="form">
        <label>Which type of parent are you?</label>
        <CheckboxGroup labels={["Biological Parent", "Step-Parent"]} onCheck={handleParentType} />
        <input type="text" placeholder="Name *" onChange={(e) => setUserName(e.target.value)} />
        <input type="email" placeholder="Email address *" onChange={(e) => setEmail(e.target.value)} />
        <input type="number" pattern="[0-9]*" inputMode="numeric" placeholder="Phone number *" onChange={(e) => setPhone(e.target.value)} />
        <input type="password" placeholder="Password *" onChange={(e) => setPassword(e.target.value)} />
        <input type="password" placeholder="Confirm password *" onChange={(e) => setConfirmedPassword(e.target.value)} />
        <PasswordChecklist
          rules={["minLength", "specialChar", "number", "capital", "match", "notEmpty"]}
          minLength={5}
          value={password}
          valueAgain={confirmedPassword}
          onChange={(isValid) => {
            if (isValid) {
              setPassword(password);
            }
          }}
        />
        {coparentInputs.map((input, index) => {
          return <span key={index}>{input}</span>;
        })}
        <button id="add-coparent-button" className="button comp-blue" onClick={addCoparent}>
          Add Another Co-Parent
        </button>
        <div className="children">
          {childrenInputs.map((input, index) => {
            return <span key={index}>{input}</span>;
          })}
        </div>
        <button id="add-child-button" className="button comp-blue" onClick={addChild}>
          Add Another Child
        </button>
        <div className="flex button-group">
          <button className="button green" onClick={submit}>
            Register <span className="material-icons-round">person_add</span>
          </button>
          <button className="button red" onClick={cancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
