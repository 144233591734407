import util from "../util";

class User {
  constructor(name = "name", id = "", allowNotifications = "Yes", email = "email", password = "password", coparents = "coparents", children = "children", phone = "phone", parentType = "parentType") {
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.password = password;
    this.children = children;
    this.coparents = coparents;
    this.parentType = parentType;
    this.allowNotifications = allowNotifications;
    this.id = util.getUid();
  }

  // Id
  set setId(id) {
    this.id = id;
  }
  get formatId() {
    return this.id;
  }

  // Name
  set setName(name) {
    this.name = name;
  }
  get getName() {
    return this.name;
  }

  // Name
  set setAllowNotifications(allowNotifications) {
    this.allowNotifications = allowNotifications;
  }
  get getAllowNotifications() {
    return this.allowNotifications;
  }

  // ParentType
  set setParentType(parentType) {
    this.parentType = parentType;
  }
  get getParentType() {
    return this.parentType;
  }

  // Phone
  set setPhone(phone) {
    this.phone = phone;
  }
  get getPhone() {
    return this.phone;
  }

  // Email
  set setEmail(email) {
    this.email = email;
  }
  get getEmail() {
    return this.email;
  }

  // Password
  set setPassword(password) {
    this.password = password;
  }
  get getPassword() {
    return this.password;
  }

  // Coparents
  set setCoparents(coparents) {
    this.coparents = coparents;
  }
  get getCoparents() {
    return this.coparents;
  }

  // Children
  set setChildren(children) {
    this.children = children;
  }
  get getChildren() {
    return this.children;
  }
}

export default User;
