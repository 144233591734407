import moment from "moment";
import emailjs from "@emailjs/browser";
const util = {
  getUid: () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  getCurrentDate: () => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${month}/${day}/${year}`;
    return currentDate;
  },
  sortArrayOfObjsWithTimestamp: (arr) => {
    return arr.sort((a, b) => moment(a.timestamp, "MM/DD/yyyy hh:mma").unix() - moment(b.timestamp, "MM/DD/yyyy hh:mma").unix());
  },
  getArraySortedByDate: (arr, prop) => {
    if (prop) {
      return arr.sort(function (a, b) {
        return new Date(a[prop]) - new Date(b[prop]);
      });
    } else {
      return arr.sort(function (a, b) {
        return new Date(b.dateAdded) - new Date(a.dateAdded);
      });
    }
  },
  createVerificationCode: () => {
    return Math.floor(100000 + Math.random() * 900000);
  },
  scrollToTopOfPage: () => {
    window.scrollTo(0, 0);
  },
  stringToDate: (str) => {
    console.log(moment(new Date(str)).format("MM/DD/yyyy"));
    console.log();
    return moment(str).format("MM/DD/yyyy");
  },
  toggleInfoSection: (e) => {
    // e.stopPropagation();
    const section = e.currentTarget.closest(".section");
    const allSections = document.querySelectorAll(".info-section.section");
    if (section.classList.contains("active")) {
      section.classList.remove("active");
    } else {
      allSections.forEach((x) => x.classList.remove("active"));
      section.classList.add("active");
    }
  },
  sendEmail: (fromName, toEmail, message, emailType) => {
    let templateId = "template_aewjhvs";
    if (emailType === "swap-request") {
      templateId = "template_eso74d8";
    }
    var data = {
      service_id: "default_service",
      template_id: templateId,
      user_id: "khikD1NoIHmBPlckL",
      template_params: {
        from_name: fromName,
        message: message,
        reply_to: toEmail,
      },
    };

    emailjs.send(data.service_id, data.template_id, { ...data.template_params }).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (error) => {
        console.log("FAILED...", error);
      }
    );
  },
  formatDate: (inputDate, inputFormat = "M-DD-YYYY", outputFormat = "MMMM DD") => {
    let inputFormatString = inputFormat;
    let inputString = inputDate;
    if (inputFormat.indexOf("-") > -1) {
      inputFormatString = inputFormat.replaceAll("-", "/");
    }
    if (inputDate.indexOf("-") > -1) {
      inputString = inputDate.replaceAll("-", "/");
    }
    return moment(inputDate, inputFormatString).format(outputFormat);
  },
  validation: (inputs) => {
    let errors = [];
    inputs.forEach((input) => {
      if (!input || input.length === 0 || input === undefined) {
        errors.push(input);
      }
    });
    return errors.length;
  },
  validateEmail: (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  isIos: () => {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  },
  applyVersionNumberToUrl: () => {
    const versionNumber = util.getUid().substring(0, 4);
    const formattedUpdateUrl = window.location.href.replaceAll(versionNumber, "");
    const formattedUpdateUrlWithOneVersion = formattedUpdateUrl.substring(0, formattedUpdateUrl.indexOf("/")) + versionNumber;
    history.replaceState(versionNumber, "", formattedUpdateUrlWithOneVersion);
  },
  formatName: (word, getFirstWordOnly, uppercaseFirstLetter) => {
    if (typeof word === "string") {
      if (!word || word === undefined || word.length === 0) {
        return word;
      }
      if (word.indexOf("  ") > -1) {
        word = word.replace("  ", " ");
      }
      if (getFirstWordOnly) {
        word = word.replace(/ .*/, "");
      }
      if (uppercaseFirstLetter) {
        return util.uppercaseFirstLetterOfAllWords(word);
      }
    } else {
      return word;
    }
    return word.toLowerCase();
  },
  addDashesToPhoneNumber: (phone) => {
    return phone.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  },
  getUniqueArray: (arr) => {
    let outputArray = Array.from(new Set(arr));
    return outputArray;
  },
  getDirectionsLink: (address) => {
    let directionsLink;
    if ((!window?.navigator?.platform.includes("Win") && navigator.platform.indexOf("iPhone") != -1) || navigator.platform.indexOf("iPod") != -1 || navigator.platform.indexOf("iPad") != -1) {
      directionsLink = `https://maps.apple.com/?daddr=${encodeURIComponent(address)}`;
    } else {
      directionsLink = `https://www.google.com/maps?daddr=${encodeURIComponent(address)}`;
    }

    return directionsLink;
  },
  formatScreenTitle: (word) => {
    return util.uppercaseFirstLetterOfAllWords(word.replace(/([a-z])([A-Z])/, "$1 $2")).replace(/([a-z])([A-Z])/, "$1 $2");
  },
  uppercaseFirstLetterOfAllWords: (mySentence) => {
    let words = mySentence;
    if (words && words !== undefined) {
      if (words.indexOf("-") > -1) {
        words = mySentence.replaceAll("-", " ").split(" ");
      } else {
        words = mySentence.split(" ");
      }
      words = words.filter((x) => x.length > 0);
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        // console.log(words);
      }
      if (words.length > 0) {
        words = words.join(" ");
      }
      // replaceAll("With", "with").replaceAll("Of", "of").replaceAll("And", "and").replaceAll("The", "the").replaceAll("As", "as");
    }
    return words;
  },
  formatPhoneNumber(phoneNumberString) {
    let input = phoneNumberString.replace("(", "").replace(")", "").replace(" ", "").substring(0, 10);

    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      input = `${areaCode}-${middle}-${last}`;
    } else if (input.length > 3) {
      input = `(${areaCode}) ${middle}`;
    } else if (input.length > 0) {
      input = `(${areaCode}`;
    }

    return input;
  },
  hasNumber: (string) => {
    return /\d/.test(string);
  },
  getUniqueArrayOfObjects: (arr, key) => {
    let setObj = new Set(arr.map(JSON.stringify));
    let output = Array.from(setObj).map(JSON.parse);
    // [...new Map(arr.map((item) => [item[key], item])).values()];
    return output;
  },
  handleCheckboxSelection: (element, checkCallback, uncheckCallback, canSelectAll = false) => {
    const clickedEl = element.currentTarget;
    const checkbox = clickedEl.querySelector(".box");

    if (checkbox.classList.contains("active")) {
      const label = clickedEl.dataset["label"];
      checkbox.classList.remove("active");
      if (checkCallback) checkCallback(label);
    } else {
      clickedEl.querySelector(".box").classList.add("active");
      const label = clickedEl.dataset["label"];
      const notActiveLabels = clickedEl.parentNode.querySelectorAll(`[data-label]:not([data-label="${label}"])`);
      if (!canSelectAll) {
        notActiveLabels.forEach((labelEl) => {
          labelEl.querySelector(".box").classList.remove("active");
        });
      }
      if (uncheckCallback) uncheckCallback(label);
    }
  },
  removeUndefinedFromArray: (arr) => {
    return arr.filter((item) => item);
  },
  isValidPassword: (password) => {
    return password.length >= 6 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /\d/.test(password) && /[#.?!@$%^&*-]/.test(password);
  },
};

export default util;
